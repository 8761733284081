<template>
  <commonHeader></commonHeader>
  <div class="wrap">
    <div class="w950 mauto ohidden">
      <h1>一、企业愿景</h1>
      <p>成为中国最具价值的新商业知识社区</p>
      <h1>二、企业使命</h1>
      <p>帮助每一个决策管理者和组织，学到更好、做到更好</p>
      <h1>三、价值观</h1>
      <p>雕琢自我，普惠他人</p>
      <h1>四、企业文化：七侠十四义</h1>
      <p>
        1、 仁：共生、普惠
        <br />2、 义：利他、共赢
        <br />3、 礼：素养、礼仪
        <br />4、 智：智慧、谋略
        <br />5、 信：诚信、一致
        <br />6、 勇：奋斗、胜利
        <br />7、 法：规范、公平
      </p>
      <h1>五、产品及服务</h1>
      <h2>1、笔记侠定位：中国领先的商业干货笔记新媒体</h2>
      <p>商业干货笔记新媒体，面向-1～6岁CEO。以笔记整理（包括文字、思维导图、知识卡片、视觉笔记）的方式，使课程方、会议方、出版社的内容在线化，从而为高质量用户提供高质量的学习，内容涉及新技术、新模式、新观点、新方法。</p>
      <h2>2、更新学堂定位：科学商业 以人为本</h2>
      <p>新型商学院。以音频和视频的方式，使课程方、会议方、出版社的内容在线化，从而为高质量用户提供高质量的学习，内容涉及商业、科学、人文。</p>
      <h1>六、产品简介</h1>
      <h2>1、笔记侠</h2>

      <p>笔记侠是中国领先的新商业干货笔记新媒体，在深度学习的碎片化时代，为80万企业决策及管理层提供最前沿的全球商业演讲、顶尖商学院课程、经典商业书籍、深度专栏等干货笔记。</p>
      <p>联合300多家课程方、出版社和1000+的商业领袖入驻，成为中国领先的新商业知识笔记干货库；2000+笔记达人遍布全球，独特的众包共创模式打造中国的“维基百科”。</p>
      <p>平台3000多篇干货笔记，包含战略、宏观趋势、组织管理、商业模式、市场营销、品牌传播、领导力、思维方式、财务法律等28个标签精准分类，受到BAT、TMD等一二线城市企业的广泛关注。</p>
      <h2>未来，更新学堂的内容定位围绕以下3各维度：</h2>
      <p>
        第一，聚合各细分垂直领域的优质导师开设内容专栏。如冯卫东升级定位24讲。
        <br />第二，全球范围内对亚洲商业精英教育有教育的线下精品课程实现线上化重构。
        <br />第三，为用户提供前沿的，有洞见的第一首商业资讯/案例解读。
      </p>
      <p>
        更新学堂，将延续“笔记侠”雕琢自我，普惠他人的价值观。
        <br />坚守竞合，继续放大对合作伙伴的优质价值放大。
        <br />对用户的价值，将从图文时代，转向未来的音视频时代。
      </p>
      <h2>2、更新学堂</h2>
      <h2>更新学堂起源</h2>
      <p>笔记侠解决了用精炼的图文帮用户还原国内外主流优质商业课程及精彩演讲内容，提升用户的前沿认知，节约用户的时间。此时用户的学习仍然缺乏更为深度系统的交互。</p>
      <p>而更新学堂则力将国内外优质的课程方、会议活动方的内容系统化、在线化的重构，通过音频、视频结合图文(精炼笔记知识卡片、思维导图等 )为用户提供更为高质量、主题性、系统性的学习，内容将设计商业学科、科学、人文等。</p>
      <h2>更新学堂的目标</h2>
      <p>
        整合全球化优质知识内容，服务亚洲未来商业精英。
        <br />成为亚洲十大商业在线教育服务管理机构之一。
      </p>

      <p>
        更新学堂，实效前沿的未来商学院。
        <br />更新学堂，商业精英的效率课堂。
        <br />更新学堂，让改变时刻发生。
      </p>
    </div>
  </div>
  <commonFooter></commonFooter>
</template>

<script>
import commonHeader from '../public/header.vue'
import commonFooter from '../public/footer.vue'
export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.wrap {
  width: 100%;
  padding: 38px 0 62px;
  background: #f9f9f9;
  font-size: 15px;
  .w950 {
    h1 {
      font-size: 22px;
      color: #111;
      line-height: 52px;
      font-weight: bold;
    }
    h2 {
      font-size: 18px;
      color: #111;
      font-weight: bold;
      line-height: 37px;
    }
    p {
      padding-bottom: 30px;
      line-height: 36px;
    }
  }
}
</style>